import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import InnerWrapper from '../../elements/innerWrapper'
import LinkButton from '../../elements/linkButton'
import SplitText from '../../components/splitText'
import Grid from '../../elements/grid'
import SectionTransition from '../../elements/activeSectionDefault'
import { breakpoint, getColor } from '../../shared/theme'
import { Content } from '../../elements/wyswyg'

import H3 from '../../elements/typography/h3'

const IntroText = styled.div`
	background: ${getColor('black')};
	padding: 60px 0;
	color: ${getColor('white')};

	@media ${breakpoint('tablet')} {
		padding: 60px 0 120px;
	}

	@media ${breakpoint('desktop')} {
		padding: 80px 0 160px;
	}
`

const IntroTextInner = styled(SectionTransition)``

const Subheading = styled.div`
	grid-column: -1 / 1;
	margin-bottom: 20px;

	@media ${breakpoint('tablet')} {
		grid-column: span 2;
	}

	@media ${breakpoint('desktop')} {
		grid-column: span 2;
	}
`

const Intro = styled(H3)`
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: span 7;
		padding-right: 2em;
	}

	@media ${breakpoint('laptop')} {
		grid-column: span 6;
		padding-right: 3em;
	}
`

export const HomeIntroText = ({ isActive, subheading, copy }) => {
	return (
		<IntroText>
			<InnerWrapper isActive={isActive}>
				<IntroTextInner isActive={isActive}>
					<Grid>
						<Subheading>
							{subheading && (
								<LinkButton
									path={subheading.url}
									title={subheading.title}
									reversed={true}
								></LinkButton>
							)}
						</Subheading>

						<Intro>
							<SplitText isActive={isActive} copy={copy} />
						</Intro>
					</Grid>
				</IntroTextInner>
			</InnerWrapper>
		</IntroText>
	)
}

HomeIntroText.propTypes = {
	isActive: PropTypes.bool,
	subheading: PropTypes.object,
	copy: PropTypes.string,
}

HomeIntroText.defaultProps = {
	isActive: true,
	subheading: {},
	copy: '',
}
